<template>
  <div class="flow-intro">
    <p class="has-text-weight-bold is-size-4 intro-title">
      {{
        isMortgage
          ? $t("af:mortgage_application.intro.title")
          : $t("af:application.intro.title")
      }}
    </p>

    <p class="intro-subtitle mb-2">
      {{
        isMortgage
          ? $t("af:mortgage_application.intro.subtitle")
          : $t("af:application.intro.subtitle")
      }}
    </p>

    <div
      v-for="(step, index) in flowConfigSteps.filter(step => !step.isHidden)"
      :key="step.id"
      class="flow-step"
    >
      <div class="flow-step__icon mr-4">
        <b-icon
          icon-pack="fa"
          :icon="step.stepIcon"
          type="is-grey-icon"
        ></b-icon>
      </div>
      <div class="flow-step__number is-hidden">
        {{ index + 1 }}
      </div>

      <span class="has-text-weight-medium step-name">{{ step.name }}</span>

      <span
        v-if="!isNaN(step.estimatedTimeToAnswerInSeconds)"
        class="has-text-grey-icon ml-2 needed-time"
      >
        -
        <span class="pl-1"
          >{{ timeToAnswer(step.estimatedTimeToAnswerInSeconds) }}
          {{
            isMortgage
              ? $t("af:mortgage_application.intro.label.step_unit")
              : $t("af:application.intro.label.step_unit")
          }}</span
        >
      </span>

      <b-tooltip
        v-if="step.description"
        class="ml-2"
        :label="step.description"
        type="is-tooltip"
        :position="isMobile ? 'is-left' : 'is-right'"
      >
        <img
          v-if="isBrand(CUSTOM_APPEARANCE.MBH)"
          alt="info-circle"
          class="my-auto is-block"
          :src="require('@/assets/images/mbh-info-shape.svg')"
        />
        <b-icon
          v-else
          class="file-icon"
          icon-pack="fa"
          icon="info-circle"
          type="is-info"
        ></b-icon>
      </b-tooltip>
    </div>

    <template v-if="isMortgage">
      <!--
      <div class="flow-step">
        <div class="flow-step__icon mr-2">
          <b-icon icon-pack="fa" icon="gavel" type="is-grey-icon"></b-icon>
        </div>
        <div class="flow-step__number mr-4 is-hidden">
          {{ flowConfigSteps.length + 1 }}
        </div>

        <span class="has-text-weight-medium step-name">{{
          $t("af:mortgage_application.intro.label.overview")
        }}</span>

        <span class="has-text-grey-icon ml-2">
          -
          <span class="pl-1">{{
            $t("af:mortgage_application.intro.label.overview.duration")
          }}</span>
        </span>
      </div>

      <div class="flow-step">
        <div class="flow-step__icon mr-2">
          <b-icon icon-pack="fa" icon="finish" type="is-grey-icon"></b-icon>
        </div>
        <div class="flow-step__number mr-4 is-hidden">
          {{ flowConfigSteps.length + 2 }}
        </div>

        <span class="has-text-weight-medium step-name">{{
          $t("af:mortgage_application.intro.label.finish")
        }}</span>
      </div>
      -->
    </template>

    <template v-else>
      <div class="flow-step" v-if="isOnline">
        <div class="flow-step__icon mr-2">
          <b-icon icon-pack="fa" icon="eye" type="is-grey-icon"></b-icon>
        </div>
        <div class="flow-step__number mr-4 is-hidden">
          {{ flowConfigSteps.length + 1 - hiddenSteps }}
        </div>

        <span class="has-text-weight-medium step-name">{{
          $t("af:application.intro.label.contract_check")
        }}</span>

        <span class="has-text-grey-icon ml-2">
          -
          <span class="pl-1 needed-time">{{
            $t("af:application.intro.label.contract_check.duration")
          }}</span>
        </span>
      </div>

      <div class="flow-step" v-if="isOnline">
        <div class="flow-step__icon mr-2">
          <b-icon icon-pack="fa" icon="signature" type="is-grey-icon"></b-icon>
        </div>
        <div class="flow-step__number mr-4 is-hidden">
          {{ flowConfigSteps.length + 2 - hiddenSteps }}
        </div>

        <span class="has-text-weight-medium step-name">{{
          $t("af:application.intro.label.contract_sign")
        }}</span>

        <span class="has-text-grey-icon ml-2">
          -
          <span class="pl-1 needed-time">{{
            $t("af:application.intro.label.contract_sign.duration")
          }}</span>
        </span>
      </div>

      <div class="flow-step" v-if="isOnline">
        <div class="flow-step__icon mr-2">
          <b-icon icon-pack="fa" icon="gavel" type="is-grey-icon"></b-icon>
        </div>
        <div class="flow-step__number mr-4 is-hidden">
          {{ flowConfigSteps.length + (isOnline ? 3 : 1) - hiddenSteps }}
        </div>

        <span class="has-text-weight-medium step-name">{{
          $t("af:application.intro.label.decision")
        }}</span>

        <span class="has-text-grey-icon ml-2">
          -
          <span class="pl-1 needed-time">{{
            $t("af:application.intro.label.decision.duration")
          }}</span>
        </span>
      </div>
    </template>

    <application-template
      v-if="isMortgage"
      template="MORTGAGE_APPLICATION_INTRO_BOTTOM"
    ></application-template>

    <application-template
      v-else
      template="APPLICATION_INTRO_BOTTOM"
    ></application-template>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { CUSTOM_APPEARANCE, FLOW_POSITION } from "@/utils/const";
import { isBrand } from "@/utils/util";

export default {
  name: "ApplicationFlowIntro",
  components: {
    ApplicationTemplate: () => import("@/components/ApplicationTemplate.vue"),
  },
  props: {
    applicationId: {
      type: Number,
      required: true,
    },
    flowConfigSteps: {
      type: Array,
      required: true,
    },
    isOnline: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    CUSTOM_APPEARANCE() {
      return CUSTOM_APPEARANCE;
    },
    isMortgage() {
      return !!this.$route.meta.mortgage;
    },
    isMobile() {
      return window.matchMedia("only screen and (max-width: 792px)").matches;
    },
    hiddenSteps() {
      return this.flowConfigSteps.filter(step => step.isHidden).length;
    },
  },
  methods: {
    isBrand,
    ...mapActions({
      savePosition: "loanApplication/savePosition",
      saveMortgagePosition: "mortgageApplication/savePosition",
    }),
    timeToAnswer(seconds) {
      return Math.floor(seconds / 60);
    },
    async startFlow() {
      if (this.isMortgage) {
        await this.saveMortgagePosition({
          id: this.applicationId,
          flowPosition: FLOW_POSITION.FLOW,
          currentFlowStepIndex: 0,
        });
      } else {
        await this.savePosition({
          id: this.applicationId,
          flowPosition: FLOW_POSITION.FLOW,
          currentFlowStepIndex: 0,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.flow-step {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1.5rem 0;

  .flow-step__icon {
    height: 40px;
    width: 40px;
    min-width: 40px;
    min-height: 40px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #dfe6ef;
  }
}

.flow-intro::v-deep {
  .required-documents {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem;

    .required-document-tile {
      background: #f3f5f9;
      border-radius: 8px;
      text-align: center;
      padding: 10px 0;
    }
  }
}

.mbh {
  .intro-title {
    color: #212121;
    font-size: 32px !important;
    line-height: 40px;
  }

  .intro-subtitle {
    font-weight: bold;
    color: #424242;
    font-size: 16px;
  }

  .flow-step__number {
    border-radius: 0;
    background-image: url("../../assets/images/mbh_forma.svg");
    height: 48px;
    width: 48px;
    min-width: 48px;
    min-height: 48px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    font-weight: 700;
    color: #203667;
    font-size: 20px;
    margin-right: 8px;
  }

  .flow-step__icon {
    display: none;
  }

  .step-name {
    font-weight: 700 !important;
    font-size: 16px;
    color: #424242 !important;
  }

  .needed-time {
    font-size: 14px;
    flex-shrink: 0;
  }

  .has-text-grey-icon {
    color: #616161 !important;
  }
}

@media only screen and (min-width: 769px) {
  .flow-intro::v-deep {
    .required-documents {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .mbh {
    .needed-time {
      font-size: 16px;
    }
  }

  .flow-step__number {
    margin-right: 16px !important;
  }
}

@media only screen and (max-width: 769px) {
  .step-name {
    max-width: 47vw;
  }
}
</style>
